import React, {useEffect, useState, useContext, useRef} from 'react';
import {
    Button, 
    Col,
    Modal,
    ModalBody,
    Label,
    Form,
    Row,
    FormFeedback,
} from 'reactstrap';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import ReactDataSheet from 'react-datasheet';
import i18n from 'i18next';
import "./DataGrid.css";
import { WomsServiceContext } from 'services/womsService';
import { useSelector } from 'react-redux';

const CostPriceModal = ({ show, onClose, storeIds }) => {
    const { womsSvc } = useContext(WomsServiceContext);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const organization = useSelector(state => state.organization);
    const user = useSelector(state => state.womsUser);
    const gridDataHeader = [
      [ { value: "Id", readOnly: true, className: "ps-4 pe-4", width: "100px" },
        { value: "Store Name", readOnly: true, className: "ps-4 pe-4" },
        { value: "SKU", readOnly: true, className: "ps-4 pe-4" },
        { value: "Cost Price (" + user.organizations?.find(o => o.id == organization.id).baseCurrency + ")", readOnly: true, className: "ps-2 pe-2", width: "150px" }
			]
    ];
		
    // Formik instance to handle form submit
    const formik = useFormik({
        initialValues: {
            id: ''
        },
        validationSchema: Yup.object(), // Using simple schema since actual validation is on grid change
        onSubmit: (values) => {
            if (!data?.some(el=> el[3].hint)) {
                setIsLoading(true);
                var priceList = data.slice(1).filter(item => item[3].isUpdated).map(item => ({
                    id: item[0].value,
                    storeName: item[1].value,
                    storeSku: item[2].value,
                    costPrice: item[3].value
                }));
                if(priceList.length > 0) {
                    womsSvc.updateCostPriceList(priceList).finally(() => {
                        
                        onClose(true);
                        setIsLoading(false);
                    });
                } else {
                    onClose(true);
                    setIsLoading(false);
                }

            }

        },
    });

    useEffect(() => {
        if (show) {
            setIsLoading(true);
            womsSvc.getCostPriceList(storeIds.includes('ALL') ? [] : storeIds).then(({ data }) => {
                const gridData = [
                    ...gridDataHeader, 
                    ...data.map(el => [
                        { value: el.id, readOnly: true, className: "ps-2 pe-2 text-left" },
                        { value: el.storeName, readOnly: true, className: "ps-2 pe-2 text-left" },
                        { value: el.storeSku, readOnly: true, className: "ps-2 pe-2 text-left" },
                        { value: el.costPrice || 0, className: "ps-2 pe-2 text-right" }
                    ])
                ];
                setData(gridData);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [show, womsSvc, storeIds]);

    const validateGridLine = (lineItem) => {
        if (!Number.isFinite(Number(lineItem[3].value))) {
            lineItem[3].hint = 'Value must be a number';
        } else {
            lineItem[3].hint = undefined;
        }
        lineItem[3].isUpdated = true;

    };

    return (
        <Modal isOpen={show} toggle={() => onClose(false)} className='cost-price-modal'>
            <div className="modal-header woms-modal-button-header">
                <h4 className="modal-title">{i18n.t("REPORT.COST_PRICE")}</h4>
                <div className="woms-modal-button-container">
                    <Button type="button" className="btn btn-secondary" onClick={() => onClose(false)} disabled={isLoading}>
                        {i18n.t("CANCEL")}
                    </Button>
                    {' '}
                    <Button type="submit" className="btn btn-success" onClick={formik.handleSubmit} disabled={isLoading}>
                        {i18n.t("SUBMIT")}
                    </Button>
                </div>
            </div>
            <ModalBody>
                <Form>
                    <Row>
                        <Col md={8}>
                        <Label className="form-label">{i18n.t("REPORT.PRICE_LIST")}</Label>
                        </Col>
                       <Col>
                       <div className="text-danger float-end">
                  {data?.some(el=> el[3].hint) && <small>{i18n.t("REPORT.INVALID_VALUE")}</small>}
                </div>
                       </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ReactDataSheet
                              data={data}
                              valueRenderer={cell => cell.value}
                              attributesRenderer={(cell) => {
                                const obj = Object.create({});
                                if (cell.hint) obj['data-hint'] = cell.hint;
                                return obj;
                              }}
                              onCellsChanged={changes => {
                                  const grid = data.map(row => [...row]);
                                  changes.forEach(({ cell, row, col, value }) => {
                                      grid[row][col] = { ...grid[row][col], value };
                                      validateGridLine(grid[row], row);
                                  });
                                  setData(grid);
                              }}
                            />
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default CostPriceModal;