import { takeLatest, takeEvery, fork, put, all, call } from "redux-saga/effects"
import { changeCurrentOrganization } from "store/actions";

//Account Redux states
import { REFRESH_WOMSUSER } from "./actionTypes"

// Is user register successfull then direct plot user in redux.
function* refreshWomsUser({ payload: womsUser }) {
        
        if (womsUser) {
          localStorage.setItem("womsUser", JSON.stringify(womsUser));
          if (womsUser.organizations && womsUser.organizations.length > 0) {

            var currentOrganization = undefined;
            try { //parse 해봐야함 
              currentOrganization = JSON.parse(localStorage.getItem("currentOrganization"));
            } catch (e) {}

            if (!currentOrganization) {
                if (womsUser.organizations[0]) {
                    currentOrganization = womsUser.organizations[0];
                    
                }
            } else {
                if (!womsUser.organizations.find(o=> o.id == currentOrganization?.id)) { // validate organizationId
                    currentOrganization = womsUser.organizations[0];
                  } else {
                    // refresh organization information
                    currentOrganization = womsUser.organizations.find(o=> o.id == currentOrganization?.id);
                  }
            }
          } else {
            // to register organization
            //if (window.location.pathname != '/logout' && window.location.pathname != '/login' && window.location.pathname != '/newRegistration') {
            //  window.location.replace("/dashboard");
            //}
            
          }
            yield put(changeCurrentOrganization(currentOrganization));
        } else {
          localStorage.removeItem("womsUser");
        }

}

export function* watchWomsUserRefresh() {
  yield takeEvery(REFRESH_WOMSUSER, refreshWomsUser)
}

function* womsUserSaga() {
  yield all([fork(watchWomsUserRefresh)])
}

export default womsUserSaga