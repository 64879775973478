import firebase from 'firebase/compat/app';


// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { refreshWomsUser } from 'store/actions';

export const ME_URL = process.env.REACT_APP_WOMS_API_URL + "/WomsUser/me";

// firebase init이 store보다 나중에 되기 때문에 redux 사용을 할수 없어서 localstorage에 넣은듯
// 근데 이러면 여러가지로 불편하다 womsuser도 localstorage에 넣는 수밖에 없게 되는데 organization sideeffect를 다루기 힘들어진다
var isRegistering = false;

class FirebaseAuthBackend {

  constructor(firebaseConfig,store) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      /*firebase.auth().onAuthStateChanged(user => {
        if (user) {
          localStorage.setItem("authUser", JSON.stringify(user));

          user.getIdToken().then((token) => { 
            fetch(ME_URL, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }).then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              response.json().then(womsUser => {
                //refreshWomsUser(womsUser);
                /*var storedUser = localStorage.getItem("authUser");
                if (!storedUser || storedUser.id != womsUser.id) { // new login
                  womsUser.currentOrganizationId = womsUser.organizations[0].id; // default organization
                  localStorage.setItem("womsUser", JSON.stringify(womsUser));
                } else { // token refresh but user might be modified
                  var currentOrganizationId = storedUser.currentOrganizationId;

                  if (womsUser.organizations.find(o=> o.id == currentOrganizationId)) { // validate organizationId
                    womsUser.currentOrganizationId = currentOrganizationId;
                  } else {
                    womsUser.currentOrganizationId = womsUser.organizations[0].id; // default organization
                  }
                  localStorage.setItem("womsUser", JSON.stringify(womsUser));
                }
              });
            })
            .catch(error => {
              localStorage.removeItem("authUser");
              //localStorage.removeItem("womsUser");
              //refreshWomsUser(womsUser);
            });

          });
          
        } else {
          localStorage.removeItem("authUser");
          //localStorage.removeItem("womsUser");
          //refreshWomsUser(womsUser);
        }
      });*/
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password,firstName,lastName) => {
    isRegistering = true;
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          ({user}) => {

            if (user.isEmailVerified) {

            const REGISTER_URL = process.env.REACT_APP_WOMS_API_URL + "/WomsUser/register"; // Replace with your actual API URL
      
            localStorage.setItem("authUser", JSON.stringify(user));
            user.getIdToken().then((token) => {
              fetch(REGISTER_URL, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                  // Include the data you want to send to your remote API
                  email: email,
                  firstName: firstName,
                  lastName: lastName,
                  fireBaseUid:user.uid
                })
            }).then(response => {
              isRegistering=false;
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              window.location.href="/new";
              /*response.json().then(womsUser => {
                localStorage.setItem("womsUser", JSON.stringify(womsUser));
              }).catch(error => {
                console.error("Error parsing JSON:", error);
              });*/
            });

            });
            resolve(firebase.auth().currentUser);

          } else {
            // email verification needed
            user.sendEmailVerification();
            window.location.href="/emailVerification";
          }
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            localStorage.setItem("authUser", JSON.stringify(user));
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            localStorage.setItem("authUser", JSON.stringify(user));
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
  * Social Login user with given details
  */

  socialLoginUser = async (type) => {
    let provider;
    if (type === "google") {
        provider = new firebase.auth.GoogleAuthProvider();
    } else if (type === "facebook") {
        provider = new firebase.auth.FacebookAuthProvider();
    }
    try {
        const result = await firebase.auth().signInWithPopup(provider);
        const user = result.user;
        localStorage.setItem("authUser", JSON.stringify(user));

        return result;
    } catch (error) {
        throw this._handleError(error);
    }
};

  addNewUserToFirestore = (user) => {
    const collection = firebase.firestore().collection("users");
    const { profile } = user.additionalUserInfo;
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp()
    };
    collection.doc(firebase.auth().currentUser.uid).set(details);
    return { user, details };
  };

  setLoggeedInUser = user => {
    localStorage.setItem("authUser", JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return JSON.parse(localStorage.getItem("authUser"));
  };

  /**
   * Returns womsUser
   */
  getWomsUser = () => {
    if (!localStorage.getItem("womsUser")) return null;
    return JSON.parse(localStorage.getItem("womsUser"));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config,refreshWomsUser) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config,refreshWomsUser);
  }
  return _fireBaseBackend;
};

const registerDispatchForFirebaseCallback = (dispatch) => {
  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      /*var storedUser = localStorage.getItem("authUser");
      var storedToken = "";
      if (storedUser) {
        storedUser = JSON.parse(storedUser);
        if (storedUser.stsTokenManager.accessToken) {
          storedToken = storedUser.stsTokenManager.accessToken;
        }
      }
      var storedWomsUser = localStorage.getItem("womsUser");
      if (storedWomsUser) {
        storedWomsUser = JSON.parse(storedWomsUser);
      }*/
      localStorage.setItem("authUser", JSON.stringify(user));

      // 모든 auth events에 대해 여길 오게 된다
      // 1 stored woms user 가 없는 거나 있어도 uid 일치 안하는 경우 - womsuser refresh 
      if (!isRegistering) {
      user.getIdToken().then((token) => { 
          fetch(ME_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }).then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            response.json().then(womsUser => {
              dispatch(refreshWomsUser(womsUser));
            }).catch(error => {
              console.error("Error parsing JSON:", error);
            });
          })
          .catch(error => {
  
            var e = error;
            // 이때는 아직 register 되고 womsUser 생성이 안된때가 포함되므로 ignore한다 
            // me 가 없이 들어올수 있는 케이스는 없다고 가정하며 me route가 작동하지 않을때라도
            // 로그인 자체는 문제가 없다
            //localStorage.removeItem("authUser");
            //dispatch(refreshWomsUser());
          });
          /*if (!storedWomsUser|| storedWomsUser.firebaseUid != user.uid) {
            
            
          } else {
            dispatch(refreshWomsUser(storedWomsUser));
          }*/
        });
      
      }
      /*user.getIdToken().then((token) => { 
        if (storedToken != token || !storedWomsUser || storedWomsUser.firebaseUid != user.uid) // refresh womsuser if token or womsuser not match or no womsuser(new login or tokenRefresh)
        {
          
            fetch(ME_URL, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }).then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              response.json().then(womsUser => {
                dispatch(refreshWomsUser(womsUser));
              }).catch(error => {
                console.error("Error parsing JSON:", error);
              });
            })
            .catch(error => {

              var e = error;
              // 이때는 아직 register 되고 womsUser 생성이 안된때가 포함되므로 ignore한다 
              // me 가 없이 들어올수 있는 케이스는 없다고 가정하며 me route가 작동하지 않을때라도
              // 로그인 자체는 문제가 없다
              //localStorage.removeItem("authUser");
              //dispatch(refreshWomsUser());
            });
            
          
        } else { 
          //localStorage.removeItem("authUser");
          //dispatch(refreshWomsUser());
        }
        

      });*/
      
    } else {
      //localStorage.removeItem("authUser");
      //dispatch(refreshWomsUser());
    }
  });

}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};


export { initFirebaseBackend, getFirebaseBackend,registerDispatchForFirebaseCallback };
