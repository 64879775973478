import { UPDATE_SALES_BY_STORE_WIDGET } from "./actionTypes";

const initialState = {
  data: {}  
};

const salesByStoreWidget = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SALES_BY_STORE_WIDGET:
      // Assuming action.payload is an entity object with an id
      const entity = action.payload;
      return { ...state, data: { ...state.data, [entity.id]: entity } };

    // Add more cases as needed

    default:
      return state;
  }
};

export default salesByStoreWidget;