import React, { useContext, useEffect, useState } from "react"

import {
    Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import i18n from 'i18next';
import classnames from "classnames"
import { Link } from "react-router-dom"
import { WomsServiceContext } from "services/womsService"
import ControlledTypehead from "components/Common/ControlledTypehead";
import { useFormik } from "formik";
import * as Yup from "yup"
import { PLATFORM_TYPE,STORE_SYNC_TYPE } from "services/womsEnum";
import cf from "../../services/codeFormatter";
import BrandModal from "pages/Brand/BrandModal";

const IntegrationSetupModal = props => {
    const { show, platformType, onClose } = props;
    const { womsSvc } = useContext(WomsServiceContext);
    const [isLoading, setIsLoading] = useState(false);
    const [storeList, setStoreList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [brandModal, setBrandModal] = useState(false);
    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])

    const initialStoreValues = {
      id: '',
      name: '',
      isActive: true,
      platformType: platformType,
      syncType: STORE_SYNC_TYPE.PASSIVE,
      url: ''
    }
    const formikFirstTab = useFormik({
        enableReinitialize: true,
        initialValues: {
          id: "",
          storeId: "",
          store: initialStoreValues
      },
        
        validationSchema: Yup.object({
          storeId: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
          store: Yup.object({
            name: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
          url: Yup.string().required(i18n.t("VALIDATION.REQUIRED"))
          .matches(
            /^(https?:\/\/)([^\s$.?#].[^\s]*)$/,
            i18n.t("VALIDATION.ENTER_FULL_URL")
          ),
          platformType: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
          syncType: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
          }),
        }),
    
        onSubmit: values => {
          setIsLoading(true);
          if (values.storeId == 'new') {
            values.store.id = '';
            womsSvc.createStore(values.store).then(({data}) => {
              formikFirstTab.setFieldValue("storeId", data.id);
              formikFirstTab.setFieldValue("store",data);
              setIsLoading(false);
            })
          } else {
            womsSvc.updateStore(values.store).then(({data}) => {
              setIsLoading(false);
            } )
          }
          
        },
      })

      
    useEffect(() => {
        if (show && platformType) {
        setIsLoading(true);
        refreshBrandList();
            womsSvc.searchStore(100,0,"id","desc","integrationId eq null and isActive eq true and platformType eq '"+platformType+"'").then(({ data }) => {
                setStoreList(data.value);
                
            }).finally(() => {
                setIsLoading(false);
            });
        }
        formikFirstTab.resetForm();
        setactiveTab(1);
    }, [show])

    const refreshBrandList = () => {
        setIsLoading(true);
        womsSvc.getBrands().then(({ data }) => {
          setBrandList(data);
          setIsLoading(false);
        });
      }

    function toggleTab(tab) {
        if (activeTab !== tab) {
        var modifiedSteps = [...passedSteps, tab]
        if (tab >= 1 && tab <= 4) {
            setactiveTab(tab)
            setPassedSteps(modifiedSteps)
        }
        }
    }

    const handleBrandModalClose = (isUpdated) => {
        if (isUpdated) {
          refreshBrandList();
        }
        setBrandModal(false);
      }

      const updateTouched = (values,isTouched) => {
        const touched = {};
        Object.keys(values).forEach((key) => {
          if (typeof values[key] === 'object' && values[key] !== null) {
            // Recursively handle nested objects
            touched[key] = updateTouched(values[key],isTouched);
          } else {
            // Mark scalar/primitive values as touched
            touched[key] = true;
          }
        });
        return touched;
      };

      
    const redirectToAuthUrl = (storeId) => {
      setIsLoading(true);
      if (platformType === PLATFORM_TYPE.SHOPIFY) {
        womsSvc.getShopifyAuthUrl(storeId).then(({ data }) => {
          if(data.authUrl) window.open(data.authUrl,'_blank');
        });
      }

    }


    return (<>

<Modal isOpen={show} size="lg">
      
        <ModalBody>
            <Row>
                <Col lg="12">
                    <h4 className="card-title mb-4">{i18n.t("SETUP_INTEGRATION")}</h4>
                    <div className="wizard clearfix">
                        <div className="steps clearfix">
                        <ul>
                            <NavItem
                            className={classnames({ current: activeTab === 1 })}
                            >
                            <NavLink
                                className={classnames({ current: activeTab === 1 })}
                                onClick={() => {
                                setactiveTab(1)
                                }}
                                disabled={!(passedSteps || []).includes(1)}
                            >
                                <span className="number">1.</span> {i18n.t("STORE")}
                            </NavLink>
                            </NavItem>
                            <NavItem
                            className={classnames({ current: activeTab === 2 })}
                            >
                            <NavLink
                                className={classnames({ active: activeTab === 2 })}
                                onClick={() => {
                                setactiveTab(2)
                                }}
                                disabled={!(passedSteps || []).includes(2)}
                            >
                                <span className="number">2.</span> {i18n.t("APP_INSTALLATION")}
                            </NavLink>
                            </NavItem>
                            <NavItem
                            className={classnames({ current: activeTab === 3 })}
                            >
                            <NavLink
                                className={classnames({ active: activeTab === 3 })}
                                onClick={() => {
                                setactiveTab(3)
                                }}
                                disabled={!(passedSteps || []).includes(3)}
                            >
                                <span className="number">3.</span> {i18n.t("CONNECT")}
                            </NavLink>
                            </NavItem>
                            <NavItem
                            className={classnames({ current: activeTab === 4 })}
                            >
                            <NavLink
                                className={classnames({ active: activeTab === 4 })}
                                onClick={() => {
                                setactiveTab(4)
                                }}
                                disabled={!(passedSteps || []).includes(4)}
                            >
                                <span className="number">4.</span> {i18n.t("COMPLETED")}
                            </NavLink>
                            </NavItem>
                        </ul>
                        </div>
                        <div className="content clearfix">

                        <TabContent activeTab={activeTab} className="body">
                            <TabPane tabId={1}>
                            <Form
                              onSubmit={e => {
                                e.preventDefault()
                                formikFirstTab.handleSubmit()
                                return false
                              }}
                            >
                                <Row>
                                <Col md="6">
                                    <div className="mb-3">
                                    <Label for="basicpill-firstname-input1">
                                        {i18n.t("STORE")}
                                    </Label>
                                    <Input
                                        name="storeId"
                                        type="select"
                                        onChange={ (e) => {
                                            formikFirstTab.handleChange(e);
                                            formikFirstTab.setTouched({});
                                            if (!e.target.value || e.target.value == 'new') {
                                                formikFirstTab.setValues({
                                                  storeId: e.target.value,
                                                  store: { ...initialStoreValues, id: e.target.value}
                                                  });
                                            } else {
                                              formikFirstTab.setValues({
                                                   storeId : e.target.value,
                                                    store: {...storeList.find(el => el.id == e.target.value)}
                                                })
                                            }
                                        }}
                                        onBlur={formikFirstTab.handleBlur}
                                        value={formikFirstTab.values.storeId || ""}
                                        invalid={formikFirstTab.touched.storeId && formikFirstTab.errors.storeId ? true : false}
                                        disabled={isLoading}
                                        >
                                        <option key={''} value={''}>
                                            {i18n.t('SELECT_OR_ADD_A_STORE')}
                                        </option>
                                        <option key={'new'} value={'new'}>
                                            {i18n.t('NEW_STORE')}
                                        </option>
                                        {storeList.map(el => (
                                            <option key={el.id} value={el.id}>
                                            {el.name}
                                            </option>
                                        ))}
                                        
                                        </Input>
                                    {formikFirstTab.touched.storeId && formikFirstTab.errors.storeId ? (
                                    <FormFeedback type="invalid">
                                        {formikFirstTab.errors.storeId}
                                    </FormFeedback>
                                    ) : null}
                                    </div>
                                </Col>
                               
            
                            </Row>
                            <Row>
                              <Col md="12">
                                <div className="mb-3">
                                  <Label className="form-label">{i18n.t('NAME')}</Label>
                                  <Input
                                    name="store.name"
                                    type="text"
                                    placeholder={i18n.t("STORE_NAME")}
                                    onChange={formikFirstTab.handleChange}
                                    onBlur={formikFirstTab.handleBlur}
                                    value={formikFirstTab.values.store.name || ""}
                                    invalid={
                                      formikFirstTab.touched.store?.name && formikFirstTab.errors.store?.name ? true : false
                                    }
                                    disabled={isLoading || !formikFirstTab.values.store.id }
                                  />
                                  {formikFirstTab.touched.store?.name && formikFirstTab.errors.store?.name ? (
                                    <FormFeedback type="invalid">{formikFirstTab.errors.store?.name}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <div className="mb-3">
                                  <Label className="form-label">{i18n.t('URL')}</Label>
                                  <Input
                                    name="store.url"
                                    type="text"
                                    placeholder="url"
                                    onChange={formikFirstTab.handleChange}
                                    onBlur={formikFirstTab.handleBlur}
                                    value={formikFirstTab.values.store?.url || ""}
                                    invalid={
                                      formikFirstTab.touched.store?.url && formikFirstTab.errors.store?.url ? true : false
                                    }
                                    disabled={isLoading || !formikFirstTab.values.store.id}
                                  />
                                  {formikFirstTab.touched.store?.url && formikFirstTab.errors.store?.url ? (
                                    <FormFeedback type="invalid">{formikFirstTab.errors.store?.url}</FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col md="6">
                              <div className="mb-3">
                                    <Label className="form-label"></Label>
                                    <div className="d-flex flex-row">
                                      <Input
                                        name="store.defaultBrandId"
                                        type="select"
                                        onChange={formikFirstTab.handleChange}
                                        onBlur={formikFirstTab.handleBlur}
                                        value={formikFirstTab.values.store.defaultBrandId || ""}
                                        invalid={formikFirstTab.touched.store?.defaultBrandId && formikFirstTab.errors.store?.defaultBrandId ? true : false}
                                        disabled={isLoading || !formikFirstTab.values.store.id}
                                      >
                                        <option key={''} value={''}>
                                          {i18n.t('NOT_SPECIFIED')}
                                        </option>
                                        {brandList.map(el => (
                                          <option key={el.id} value={el.id}>
                                            {el.name}
                                          </option>
                                        ))}
                                        
                                      </Input>
                                      &nbsp;
                                      {formikFirstTab.values.store.id && <Button outline onClick={() => { setBrandModal(true) }}>
                                        {formikFirstTab.values.store.defaultBrandId ? <i className="mdi mdi-pencil" /> : <i className="mdi mdi-plus" />}
                                      </Button>}
                                    </div>
                                    {formikFirstTab.touched.store?.defaultBrandId && formikFirstTab.errors.store?.defaultBrandId ? (
                                      <FormFeedback type="invalid">{formikFirstTab.errors.store?.defaultBrandId}</FormFeedback>
                                    ) : null}
                                  </div>
                              </Col>
                            </Row>
                            
                            </Form>
                            </TabPane>
                            <TabPane tabId={2}>
                            <div>
                                <Form>
                                <Row className="m-4">
                                    <Col className="d-flex justify-content-center">
                                    <Button color="warning" onClick={() => { window.open('https://apps.shopify.com/?show_store_picker=1', '_blank')}}>
                                    {i18n.t('APP_LINK')}
                                      </Button>
                                    </Col>
                                </Row>
                                <Row  className="m-4">
                                  <Col className="d-flex justify-content-center">{i18n.t('INSTALLATION_INSTRUCTION')}</Col>
                                </Row>
                                </Form>
                            </div>
                            </TabPane>
                            <TabPane tabId={3}>
                            <div>
                                <Form>
                                <Row className="m-4">
                                    <Col className="d-flex justify-content-center">
                                    <Button color="warning" onClick={() => { redirectToAuthUrl(formikFirstTab.values.storeId);}} disabled={isLoading || !formikFirstTab.values.storeId}>
                                    {i18n.t('CONNECT')}
                                      </Button>
                                    </Col>
                                </Row>
                                <Row  className="m-4">
                                  <Col className="d-flex justify-content-center">{i18n.t('CONNECTION_INSTRUCTION')}</Col>
                                </Row>
                                </Form>
                            </div>
                            </TabPane>
                            <TabPane tabId={4}>
                            <div className="row justify-content-center">
                                <Col lg="6">
                                <div className="text-center">
                                    <div className="mb-4">
                                    <i className="mdi mdi-check-circle-outline text-success display-4" />
                                    </div>
                                    <div>
                                    <h5>Confirm Detail</h5>
                                    <p className="text-muted">
                                        If several languages coalesce, the grammar
                                        of the resulting
                                    </p>
                                    </div>
                                </div>
                                </Col>
                            </div>
                            </TabPane>
                        </TabContent>
                        </div>
                        <Row>
                            <Col className="ms-3">
                            <Button
                                color="secondary"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                {i18n.t("CLOSE")}
                            </Button>
                            </Col>
                            
                            <Col className="d-flex justify-content-end me-3">
                            { activeTab != 1 && <Button
                            className=""
                                color="primary"
                                disabled={activeTab === 1}
                                onClick={() => {
                                    toggleTab(activeTab - 1);
                                }}
                            >
                                {i18n.t("PREVIOUS")}
                            </Button> }
                            
                            {(activeTab != 3 && activeTab !=4) && <Button className="ms-2" 
                                color="primary"
                                disabled={isLoading}
                                onClick={() => {
                                  if (activeTab == 1) {
                                    formikFirstTab.validateForm().then((errors) => {

                                      if (errors.storeId) {
                                        formikFirstTab.setTouched({storeId: true});
                                      } else {
                                        formikFirstTab.setTouched({store:updateTouched(formikFirstTab.values.store,true)})
                                      }
  
                                      if (Object.keys(errors).length == 0) {
                                        setIsLoading(true);
                                        formikFirstTab.submitForm().then(() => {
                                          setIsLoading(false)
                                          toggleTab(activeTab + 1);
                                        })
                                      }
                                      
                                    }); // Trigger validation on all fields
                                  } else {
                                    toggleTab(activeTab + 1);
                                  }
                                  
                                 
                                  
                                }}
                            >
                                {i18n.t("NEXT")}
                            </Button>}
                            </Col>
                        </Row>

                        <div >
                
                

                        </div>
                        
                    </div>
                </Col>
                
            </Row>
            </ModalBody>
            </Modal>
            <BrandModal
            show={brandModal}
            id={formikFirstTab.values.store?.defaultBrandId}
            onClose={handleBrandModalClose}
          />
          </>
    )
}

export default IntegrationSetupModal
