import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div style={{display:"flex"}}><h4 className="mb-0 pt-2 font-size-18">{props.altTitle || props.breadcrumbItem}</h4><div style={{paddingLeft:"10px"}}>{props.button}</div></div>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {props.title &&<BreadcrumbItem>
                <Link to="#">{props.title}</Link>
              </BreadcrumbItem>}
              <BreadcrumbItem active>
                <Link to="#">{props.breadcrumbItem}</Link>
                {props.breadcrumbButton}
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
