import { UPDATE_BATCH_RESULT } from "./actionTypes";

const initialState = {
  data : 1
};

const batchResult = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BATCH_RESULT:
      state = { ...state, data: state.data + 1};
      break;
    
    default:
      break;
  }
  return state;
};

export default batchResult;
