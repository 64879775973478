import { UPDATE_HUB_CONNECTION } from "./actionTypes";

const initialState = false;

const hubconnectionStatus = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_HUB_CONNECTION:
      state = action.payload;
      break;
    
    default:
      break;
  }
  return state;
};

export default hubconnectionStatus;
