import { UPDATE_SALES_WIDGET } from "./actionTypes";

const initialState = {
  data : {}
};

const salesWidget = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SALES_WIDGET:
      state = { ...state, data: action.payload};
      break;
    
    default:
      break;
  }
  return state;
};

export default salesWidget;
