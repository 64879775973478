import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { WomsServiceContext } from "services/womsService"
import Select from "react-select"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/bootstrap.css"
import Moment from "react-moment"
import i18n from "i18next"
import { withTranslation } from "react-i18next"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import { USER_STATUS, USER_ROLE } from "services/womsEnum"
import { registerUser, apiError, refreshWomsUser } from "../../store/actions"
import { getRoles } from "helpers/woms_utils"
import cf from "../../services/codeFormatter";
import { getUserRoleCssClasses } from "./UserUIHelper"
import Avatar from "react-avatar"
import Resizer from "react-image-file-resizer";

const UserModal = props => {
  const { show, isEdit, id, onClose, errorMessage,isProfile } = props
  const { womsSvc } = useContext(WomsServiceContext)
  const [isLoading, setIsLoading] = useState(false)
  const [imagePreview, setImagePreview] = useState("");
  const fileInputRef = React.useRef();
  const organization = useSelector(state => state.organization);
  const womsUser = useSelector(state => state.womsUser);
  const dispatch = useDispatch();

  const resizeFile = (file) =>
  new Promise((resolve) => {
      Resizer.imageFileResizer(
          file,
          100,
          100,
          "JPEG",
          80,
          0,
          (uri) => {
              resolve(uri);
          },
          "base64"
      );
});
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      status: "ACTIVE",
      email: "",
      firstName: "",
      lastName: "",
      phoneCountryNo: "",
      phoneNo: "",
      createdOn: null,
      firebaseUid : "",
      files : [],
      base64ImageData : ""
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      lastName: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      email: Yup.string()
        .email(i18n.t("VALIDATION.VALID_EMAIL"))
        .required(i18n.t("VALIDATION.REQUIRED"))
        .min(5, i18n.t("VALIDATION.MINIMUM_5_CHARACTERS"))
        .max(50, i18n.t("VALIDATION.MAXIMUM_50_CHARACTERS")),
      phoneNo: Yup.string()
        .required(i18n.t("VALIDATION.REQUIRED"))
        .min(5, i18n.t("VALIDATION.MINIMUM_5_CHARACTERS"))
        .max(50, i18n.t("VALIDATION.MAXIMUM_50_CHARACTERS")),
      status: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
    }),

    onSubmit: values => {
      if (imagePreview) {
        values.base64ImageData = imagePreview;
      }
        womsSvc.updateUser(values).then(({ data }) => {
          formik.resetForm();
          onClose(true);

          if (values.id == womsUser.id) {
            womsSvc.me().then(({ data }) => {
              dispatch(refreshWomsUser(data));
            });
          }

        })
      } 
  })

  useEffect(() => {
    if (show) {
      if (id) {
        setIsLoading(true)
        setImagePreview("");
        if (id == womsUser.id) {
          womsSvc.me().then(({ data }) => {
            formik.setValues(data)
            setIsLoading(false)
          })
        } else {
          womsSvc.getUser(id).then(({ data }) => {
            formik.setValues(data)
            setIsLoading(false)
          })
        }

      }
    } else {
      formik.resetForm()
    }
  }, [show])

  const handleFileChange = async (e) =>{
    setIsLoading(true);
    e.preventDefault();
    let file = e.target.files[0];
    var thumbnail = await resizeFile(file);
    if(thumbnail) setImagePreview(thumbnail);
    setIsLoading(false);
    return true;
  }
  const onEditClick = () => {
    fileInputRef.current.click();
};
  return (
    <Modal isOpen={show} size="lg">
      <ModalHeader tag="h4">{isEdit ? i18n.t("EDIT_USER") : i18n.t("DETAILS")}</ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            formik.handleSubmit()
            return false
          }}
        >
          <Row>
          </Row>
          <Row>
          <Col md="2">
              <div className="mt-1 mb-3 ms-3">
                <Avatar name={formik.values.firstName + " " + formik.values.lastName} 
									src={imagePreview ? imagePreview : formik.values.imageUrl} 
									size="60" 
									round="30px" 
								/>
                <Button
                disabled={isLoading}
                type="button"
                className="btn btn-light btn-sm woms-avatar-btn"
                onClick={onEditClick}
                >
              <i className="bx bx-pencil" />
              <input 
                  type="file"  
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  name="profile_avatar" 
                  accept=".png, .jpg, .jpeg, .webp"
                  style={{ display: 'none' }}
                />
                </Button>
                </div>
            </Col>
            <Col md="5">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("FIRST_NAME")}</Label>
                <Input
                  name="firstName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName ? formik.values.firstName : ""}
                  invalid={
                    formik.touched.firstName && formik.errors.firstName
                      ? true
                      : false
                  }
                  disabled={isLoading || !isEdit }
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <FormFeedback type="invalid">
                    {formik.errors.firstName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="5">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("LAST_NAME")}</Label>
                <Input
                  name="lastName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName ? formik.values.lastName : ""}
                  invalid={
                    formik.touched.lastName && formik.errors.lastName
                      ? true
                      : false
                  }
                  disabled={isLoading || !isEdit}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <FormFeedback type="invalid">
                    {formik.errors.lastName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("PHONE_NO")}</Label>
                <PhoneInput
                  name="phoneNo"
                  country={"us"}
                  preferredCountries={["cn", "kr", "us"]}
                  enableSearch={true}
                  value={formik.values.phoneCountryNo + formik.values.phoneNo + ""}
                  onChange={(phone, country) => {
                    formik.setFieldValue(
                      "phoneNo",
                      phone.slice(country.dialCode.length)
                    )
                    formik.setFieldValue("phoneCountryNo", country.dialCode)
                  }}
                  disabled={isLoading || !isEdit}
                  inputClass={
                    formik.touched.phoneNo && formik.errors.phoneNo
                      ? "is-invalid"
                      : ""
                  }
                  searchPlaceholder={i18n.t("SEARCH_DEFAULT_OPTION")}
                />
                {formik.touched.phoneNo && formik.errors.phoneNo ? (
                  <FormFeedback type="invalid">
                    {formik.errors.phoneNo}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="8">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("EMAIL")}</Label>
                <Input
                  name="email"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email ? formik.values.email : ""}
                  invalid={
                    formik.touched.email && formik.errors.email ? true : false
                  }
                  //disabled={isLoading || !isEdit}
                  disabled={true}
                />
                {formik.touched.email && formik.errors.email ? (
                  <FormFeedback type="invalid">
                    {formik.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("ROLE")}</Label>
                <br/>
                {formik.values.userRoles && formik.values.userRoles.filter(u => !u.organizationId || u.organizationId== organization.id).length ==0 ? <span className="badge badge-soft-primary me-2">{i18n.t("ROLE.USER")}</span> : formik.values.userRoles?.filter(u => !u.organizationId || u.organizationId== organization.id).map(r => <span className={getUserRoleCssClasses(r.role) + " me-2"} key={r.id}>
                  {cf.format("UserRole",r.role)}
                </span>)}
              </div> 
            </Col>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("ORGANIZATION")}</Label>
                <br/>
                {formik.values.organizations && formik.values.organizations?.map(o => <span className="badge badge-soft-secondary me-2" key={o.id}>
                  {o.legalName}
                </span>)}
              </div>
            </Col>
          </Row>
          {!isProfile && <Row>
              <Col md="6">
                <div className="mb-3">
                  <Label className="form-label">{i18n.t("STATUS")}</Label>
                  <Input
                    name="status"
                    type="select"
                    className="form-select"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.status || ""}
                    disabled={isLoading || !isEdit}
                    invalid={
                      formik.touched.status && formik.errors.status
                        ? true
                        : false
                    }
                  >
                    <option value={USER_STATUS.ACTIVE}>{i18n.t("ACTIVE")}</option>
                    <option value={USER_STATUS.INACTIVE}>{i18n.t("INACTIVE")}</option>
                  </Input>
                  {formik.touched.status && formik.errors.status ? (
                    <FormFeedback type="invalid">
                      {formik.errors.status}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            
          <Col md="6">
            <div className="mb-3">
              <Label className="form-label">{i18n.t("CREATED_ON")}</Label>
              <br />
              <p className="mt-2">
                <Moment format="lll">{formik.values.createdOn}</Moment>
              </p>
            </div>
          </Col>
            
          </Row>}
          <Row>
            <Col md="12">
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => onClose()}
                  disabled={isLoading || formik.isSubmitting}
                >
                  {isEdit ? i18n.t("CANCEL") : i18n.t("CLOSE")}
                </button>
                &nbsp;&nbsp;
                {isEdit && <button
                  type="submit"
                  className="btn btn-success save-integration"
                  disabled={isLoading || formik.isSubmitting}
                >
                  {i18n.t("SAVE")}
                </button>}
              </div>
            </Col>
                </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}
export default withTranslation() (UserModal)
