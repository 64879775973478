import axios from 'axios';
import React from 'react';

export const WomsServiceContext = React.createContext();

class WomsService {

    constructor() { }

    // order
    getOrder(id) {
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/Order/" + id);
    }
    updateOrder(order) {
        return axios.put(process.env.REACT_APP_WOMS_API_URL + "/Order/" + order.id, order);
        // return axios.patch(process.env.REACT_APP_WOMS_API_URL + "/Order/" + order.id, order);

    }
    searchOrder(top, skip, orderBy, order, query) {
        //return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/Orders?$select=id,orderNo,status,currency,createdOn&$expand=store($select=name),shippingAddress($select=firstName,lastName),orderLineItems($select=price,quantity)&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/Orders?$select=id,orderNo,status,currency,createdOn,orderedOn,totalAmount,totalItems&$expand=store($select=name),shippingAddress($select=firstName,lastName)&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }
    exportOrder(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/Orders/ExportCsv?" + (top ? ("$top=" + top + "&$skip=" + skip +"&") : "")+ "$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""), {
            responseType: 'blob'
          });
    }
    exportOrderLine(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/Orders/ExportLineCsv?" + (top ? ("$top=" + top + "&$skip=" + skip +"&") : "")+ "$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""), {
            responseType: 'blob'
          });
    }

    // Fulfillment
    getOrderFulfillments(orderId) {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/Fulfillments?$select=id,status,trackingNo,createdOn&$expand=location($select=name)&$count=true&$filter=" + encodeURIComponent("orderId eq " + orderId));
    }
    getFulfillment(id) {
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/Fulfillment/" + id);
    }

    // ActivityLog
    getActivityLogsForOrder(orderId) {
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/Order/" + orderId + "/ActivityLogs");
    }
    getActivityLogsForProduct(productId) {
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/Product/" + productId + "/ActivityLogs");
    }
    createActivityLog(log) {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/ActivityLog", log);
    }

    deleteActivityLog(logId) {
        return axios.delete(process.env.REACT_APP_WOMS_API_URL + "/ActivityLog/" + logId);
    }


    //User
    me(){
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/WomsUser/me");
    }
    searchUser(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/WomsUsers?$select=id,email,firstName,lastName,status,createdOn&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }
 
    getUser(id){
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/WomsUser/" + id);
    }

    createUser(user){
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/WomsUser", user);
    }

    updateUser(user){
        return axios.put(process.env.REACT_APP_WOMS_API_URL + "/WomsUser/" + user.id, user);
    }

    deleteUser(id){
        return axios.delete(process.env.REACT_APP_WOMS_API_URL + "/WomsUser/" + id);
    }
    getCurrentUser(){
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/WomsUser/me");
    }
    removeUserFromOrganization(userId){
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/WomsUser/RemoveFromOrganization/"+ userId);
    }
    inviteUser(invite) {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/WomsUser/invite", invite);
    }

    //Enrollment
    createEnrollment(plan) {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/Enrollment", {"subscriptionPlan" : plan});
    }
    cancelEnrollment() {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/Enrollment/cancel");
    }

    //Billing
    getBillingHistory() {
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/Billing/PaymentHistory");
    }

    //Location
    searchLocation(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/Locations?$select=id,name,province,phone,country,city,isActive,createdOn&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }
    getLocation(id){
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/Location/" + id);
    }
    createLocation(location){
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/Location", location);
    }
    updateLocation(location){
        return axios.put(process.env.REACT_APP_WOMS_API_URL + "/Location/" + location.id, location);
    }
    deleteLocation(id) {
        return axios.delete(process.env.REACT_APP_WOMS_API_URL + "/Location/" + id);
    }

    //StoreListing 
    searchStoreListing(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/StoreListings?$select=id,storeSku,name,productId,storeId,refNumber,refNumber2,syncStatus,syncResult,lastSyncedOn,createdOn&$expand=store($select=name,platformType),product($select=id,sku),brand($select=name)&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }
    getCostPriceList(storeIds) {
        if (storeIds && storeIds.length > 0) {
            const query = storeIds.map(id => `storeIds=${encodeURIComponent(id)}`).join('&');
            return axios.get(`${process.env.REACT_APP_WOMS_API_URL}/StoreListing/costPrice?${query}`);
        } else {
            return axios.get(`${process.env.REACT_APP_WOMS_API_URL}/StoreListing/costPrice`);
        }
    }
    updateCostPriceList (priceList) {
        return axios.put(process.env.REACT_APP_WOMS_API_URL + "/StoreListing/costPrice" ,priceList);
    }
    updateStoreListingBrand (list,brandId) {
        return axios.put(process.env.REACT_APP_WOMS_API_URL + "/StoreListing/brand/" + brandId ,list);
    }

    getStoreListing(id) {
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/StoreListing/" + id);
    }
    updateStoreListing(storeListing) {
        return axios.put(process.env.REACT_APP_WOMS_API_URL + "/StoreListing/" + storeListing.id, storeListing);
    }
    deleteStoreListing(id) {
        return axios.delete(process.env.REACT_APP_WOMS_API_URL + "/StoreListing/" + id);
    }
    deleteStoreListings(ids) {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/StoreListing/BulkDelete",ids);
    }
    getNewStoreListingsCount() {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/StoreListings/$count?$filter=productId eq null and syncStatus eq 'NONE'");
    }
    // syncRequest
    createBatchRequest(payload) {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/BatchRequest", payload);
    }
    getProductStoreListings(productId) {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/StoreListings?$select=id,storeSku,name,storeId,refNumber,refNumber2,createdOn&$expand=store($select=name)&$count=true&$filter=" + encodeURIComponent("productId eq " + productId));
    }
    // brands
    getBrands() {
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/Brand");
    }
    getBrand(id) {
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/Brand/" + id);
    }
    createBrand(brand) {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/Brand",brand);
    }
    updateBrand(brand) {
        return axios.put(process.env.REACT_APP_WOMS_API_URL + "/Brand/" + brand.id, brand);
    }

    //Store
    searchStore(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/Stores?$select=id,name,platformType,syncType,isActive,url,defaultBrandId,integrationId,createdOn&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }
    getStore(id) {
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/Store/" + id);
    }
    createStore(store) {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/Store", store);
    }
    updateStore(store) {
        return axios.put(process.env.REACT_APP_WOMS_API_URL + "/Store/" + store.id, store);
    }
    deleteStore(id) {
        return axios.delete(process.env.REACT_APP_WOMS_API_URL + "/Store/" + id);
    }

    // integration
    getShopifyAuthUrl(storeId) {
        return axios.get(process.env.REACT_APP_WOMS_OAUTH_URL + "/shopify/authorize?storeId=" + storeId);
    }

    shopifyCallback(state,code) {
        return axios.get(process.env.REACT_APP_WOMS_OAUTH_URL + "/shopify/callback?code=" + code + "&state=" + state);
    }


    //Product
    searchProduct(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/Products?$select=id,status,name,sku,thumbnailUrl,createdOn&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }



    //searchProduct(top, skip, orderBy, order, query) {
    //    return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/Products?$select=id,status,name,sku,thumbnailUrl,createdOn&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + "&$filter=bundleType eq 'STANDARD'" + (query ?  + " and " + encodeURIComponent(query) : ""));
    //}
    getProduct(id) {
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/Product/" + id);
    }
    createProduct(product) {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/Product/", product);
    }
    updateProduct(product) {
        return axios.put(process.env.REACT_APP_WOMS_API_URL + "/Product/" + product.id, product);
    }
    getThumbnails(skus) {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + `/Products?$select=id,sku,thumbnailUrl&$filter=sku in (${skus.map(sku => `'${sku}'`).join(',')})`);
    }
    updateTags(productId, tags) {
        return axios.put(process.env.REACT_APP_WOMS_API_URL + "/Product/" +  productId + "/Tags",tags);
    }

    //Bundle
    searchBundle(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/Products?$select=id,sku,name,status,bundleType,createdOn,bundleDetailBundleProducts&$expand=bundleDetailBundleProducts($select=quantity,product;$expand=product($select=sku,name))&$filter=bundleDetailBundleProducts/any()&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }
    searchProductForBundleEntry(organizationId) {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/Products?$select=id,sku&$filter=not (bundleDetailBundleProducts/any()) and bundleType eq 'STANDARD' and organizationId eq " + organizationId + "&$count=true&$orderby=sku asc");
    }


    
    //Organization
    searchOrganization(top, skip, orderBy, order, query){
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/Organizations?$select=id,legalName,localizedLegalName,baseCurrency,country,idNumber,createdOn&$count=true&$top=" + top + "&$skip="  + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }
    getOrganization(id){
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/Organization/" + id);
    }
    createOrganization(organization){
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/Organization", organization);
    }
    updateOrganization(organization){
        return axios.put(process.env.REACT_APP_WOMS_API_URL + "/Organization/" + organization.id, organization);
    }
    deleteOrganization(id){
        return axios.delete(process.env.REACT_APP_WOMS_API_URL + "/Organization/" + id);
    }



    //Role
    searchRole(top, skip, orderBy, order, query){
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/Roles?$select=id,name&$count=true&$top=" + top + "&$skip="  + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }
    getRole(id){
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/Role/" + id);
    }
    getRoles(){
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/Role");
    }

    // Tag
    getAllTags(){
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/Tag");
    }
    createTag(tag) {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/Tag" , tag);
    }



    // entityFilter
    getEntityFilter(id) {
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/EntityFilter/" + id);
    }
    updateEntityFilter(entityFilter) {
        return axios.put(process.env.REACT_APP_WOMS_API_URL + "/EntityFilter/" + entityFilter.id,entityFilter);
    }
    createEntityFilter(entityFilter) {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/EntityFilter" , entityFilter);
    }
    deleteEntityFilter(id) {
        return axios.delete(process.env.REACT_APP_WOMS_API_URL + "/EntityFilter/" + id);
    }

    searchEntityFilter(top,skip,orderBy,order,query)
    {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/EntityFilters?$select=id,description,filterValue,entityType,userId&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }

    // notifications
    createNotification(notification) {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/Notification" , notification);
    }
    searchNotifications(top,skip,orderBy,order,query)
    {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/Notifications?$select=id,type,content,referenceRoute,sentById,createdOn&expand=sentBy($select=firstName,lastName,imageUrl),userNotifications&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }
    deleteNotification(id) {
        return axios.delete(process.env.REACT_APP_WOMS_API_URL + "/Notification/" + id);
    }
    getUnreadCount() {
        return axios.get(process.env.REACT_APP_WOMS_API_URL + "/Notification/unread");
    }

    markAsRead(ids) {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/Notification/markread", ids);
    }
    betaFeedback(notification) {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/Notification/betaFeedback" , notification);
    }
    // currencyRate
    searchCurrencyRates(top,skip,orderBy,order,query) {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/CurrencyRates?$select=id,targetCurrencyCode,baseCurrencyCode,rate,rateOn&$count=true&$top=" + top + "&$skip=" + skip + "&$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""));
    }
    exportCurrencyRates(top, skip, orderBy, order, query) {
        return axios.get(process.env.REACT_APP_WOMS_ODATA_URL + "/CurrencyRates/ExportCsv?" + (top ? ("$top=" + top + "&$skip=" + skip +"&") : "")+ "$orderby=" + orderBy + " " + order + (query ? "&$filter=" + encodeURIComponent(query) : ""), {
            responseType: 'blob'
          });
    }
    // dashboard
    requestDashboard(type,params,isForceUpdate) {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/Dashboard/" + type + (isForceUpdate ? '?isForceUpdate=true' : '') , params);
    }

    // report
    requestReport(type,params) {
        return axios.post(process.env.REACT_APP_WOMS_API_URL + "/Report/" + type, params);
    }

    makeFilterString(filters) {
        // {field:"",operator:"",value:""}
        var returnString = "";
        filters.forEach(entry => {
            if ((entry.value || entry.value === false || entry.value === null) && entry.field) {
                returnString = returnString.concat((returnString == "" ? "" : " and ") + (entry.field + " " + entry.operator + " " +
                    (entry.value === null ? "null" : (typeof entry.value === 'string' || entry.value instanceof String) ? ("'" + entry.value + "'") :
                        ((typeof entry.value.getMonth === 'function' || entry.value instanceof Date) ? ("cast(" + entry.value.toISOString() + ",Edm.DateTimeOffset)") : 
                        entry.value))));
            } else if (entry.raw) {
                returnString = returnString.concat((returnString == "" ? "" : " and ") + entry.raw);
            }
        });
        return returnString;
    }

    insertUrlParam(kvObj) {
        if (kvObj && Object.keys(kvObj).length>0) {
            let searchParams = new URLSearchParams(window.location.search);
            Object.keys(kvObj).forEach(key => {
                if (kvObj[key] !== '') {
                    searchParams.set(key, kvObj[key]) }
                    else {
                    searchParams.delete(key);
                }
            })
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
            window.history.replaceState({ path: newurl }, '', newurl);
        } else if (Object.keys(kvObj).length == 0) {
            let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
            window.history.replaceState({ path: newurl }, '', newurl);
        }
    }
}

export default WomsService;