import { UPDATE_AVG_PRICE_WIDGET } from "./actionTypes";

const initialState = {
  data : {}
};

const avgPriceWidget = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_AVG_PRICE_WIDGET:
      state = { ...state, data: action.payload};
      break;
    
    default:
      break;
  }
  return state;
};

export default avgPriceWidget;
