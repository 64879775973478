import { UPDATE_NEW_LISTINGS } from "./actionTypes";

const initialState = {
  newListings : 0
};

const badge = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NEW_LISTINGS:
      if (state.newListings == action.payload) return state;
      state = { ...state, newListings: action.payload};
      break;
    
    default:
      break;
  }
  return state;
};

export default badge;
