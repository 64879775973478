import React, { useEffect, useState, useMemo, useContext } from "react"
import Moment from "react-moment"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
  Input,
  FormFeedback,
  Label,
  Form,
  Dropdown,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import { WomsServiceContext } from "services/womsService"
import cf from "../../services/codeFormatter"
import i18n from "i18next"
import { withTranslation } from "react-i18next"
import shopify from "../../assets/images/brands/shopify-icon.svg";
import amazon from "../../assets/images/brands/amazon-a-logo-icon.svg";
import woocommerce from "../../assets/images/brands/woocommerce-icon.svg";
import shopee from "../../assets/images/brands/shopee-icon.svg";
import sabang_net from "../../assets/images/brands/sabanglogo.png";
import flipshop from "../../assets/images/brands/flipshop_logo.png";
import ebay from "../../assets/images/brands/ebay_logo.png";
import shein from "../../assets/images/brands/shein_logo.png";
import tiktok from "../../assets/images/brands/tiktok_logo.png";
import IntegrationSetupModal from "./IntegrationSetupModal"
import { PLATFORM_TYPE } from "services/womsEnum"

const IntegrationList = props => {
  //meta title
  document.title = i18n.t("INTEGRATIONS");

  const { womsSvc } = useContext(WomsServiceContext);
  const [modal, setModal] = useState(false);
  const [platformType, setPlatformType] = useState();

  const handleClose = () => {
    setModal(false);
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs altTitle={i18n.t("INTEGRATION_LIST")} title={i18n.t("INTEGRATIONS")} breadcrumbItem={i18n.t("LIST")} />
          <Row>
            <Col xs="4">
              <Card>
                <CardBody>
                    <h5>{i18n.t("SHOPIFY")}</h5>
                    <img height="35px" src={shopify}></img>
                    <br></br>
                    <div className="d-flex justify-content-end">
                        <Button color="primary" className="float-right" onClick={() => {
                            setPlatformType(PLATFORM_TYPE.SHOPIFY);
                            setModal(true);
                        }}>{i18n.t("SET_UP")}</Button>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <IntegrationSetupModal platformType={platformType} show={modal} onClose={handleClose}></IntegrationSetupModal>
    </>
  )
}

export default withTranslation()(IntegrationList)
