import React, { useEffect, useState, useMemo, useContext } from "react"
import { useParams, useLocation } from "react-router-dom"
import i18n from "i18next"
import { withTranslation } from "react-i18next"
import { Container } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { WomsServiceContext } from "services/womsService"

const IntegrationSuccess = props => {
  //meta title
  document.title = "Success"
  const { womsSvc } = useContext(WomsServiceContext);
  const womsUser = useSelector(state => state.womsUser);

  const { storeId } = useParams();

  useEffect(() => {

    window.close();
    // TODO: update original window using storeId / redux state

  }, [storeId]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
         Success!
        </Container>
      </div>
    </>
  )
}

export default withTranslation()(IntegrationSuccess)