import React, { useContext, useEffect, useState } from "react"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form,
} from "reactstrap"
import { useFormik } from "formik"
import * as Yup from "yup"
import { WomsServiceContext } from "services/womsService"
import { useSelector } from "react-redux"
import i18n from "i18next"
import { withTranslation } from "react-i18next"

const UserInviteModal = props => {
  const { show, onClose } = props
  const { womsSvc } = useContext(WomsServiceContext)

  const womsUser = useSelector(state => state.womsUser);
  const currentOrg = useSelector(state => state.organization);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
    }),

    onSubmit: values => {
      womsSvc.inviteUser(values).then(({ data }) => {
        formik.resetForm();
        onClose(true,null,true);
      }).catch((err) => {
        formik.resetForm();
        onClose(false, err.response.data);
      });
    } 
  })

  useEffect(() => {
      formik.resetForm();
  }, [show])

  return (
    <Modal isOpen={show} size="md">
      <ModalHeader tag="h4">{i18n.t("INVITE_USER")}</ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            formik.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col md="12">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("ORGANIZATION")}</Label>
                <Input
                  name="organization"
                  type="text"
                  value={womsUser?.organizations?.find(o=> o.id == currentOrg.id)?.legalName}
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("EMAIL")}</Label>
                <Input
                  name="email"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email ? formik.values.email : ""}
                  invalid={
                    formik.touched.email && formik.errors.email ? true : false
                  }
                />
                {formik.touched.email && formik.errors.email ? (
                  <FormFeedback type="invalid">
                    {formik.errors.email}
                  </FormFeedback>
                ) : null}
              </div>{" "}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => onClose()}
                  disabled={formik.isSubmitting}
                >
                  {i18n.t("CANCEL")}
                </button>
                &nbsp;&nbsp;
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={formik.isSubmitting}
                >
                  {i18n.t("SAVE")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}
export default withTranslation()(UserInviteModal)
