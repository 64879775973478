import { UPDATE_SKU_TREND_WIDGET } from "./actionTypes";

const initialState = {
  data: {}  
};

const skuTrendWidget = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SKU_TREND_WIDGET:
      return { ...state, data: action.payload};

    default:
      return state;
  }
};

export default skuTrendWidget;