import { CHANGE_ORGANIZATION } from "./actionTypes";

const initialState = {
  id : ''
};

const organization = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_ORGANIZATION:
      //if (state.id == action.payload?.id) return state;
      state = { ...state, id: action.payload?.id, subscriptionPlan: action.payload?.subscriptionPlan, isNeedsUpgrade: action.payload?.isNeedsUpgrade};
      break;
    
    default:
      break;
  }
  return state;
};

export default organization;
