import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { UPDATE_UNREAD_COUNT } from "./actionTypes"

export function* unreadNotificationChange({payload:count}) {
}

export function* watchUnreadNotificationChange() {
    yield takeEvery(UPDATE_UNREAD_COUNT, unreadNotificationChange)
  }

function* notificationSaga() {
    yield all([fork(watchUnreadNotificationChange)])
}

export default notificationSaga
